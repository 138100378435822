import _forIn from "for-in";
var exports = {};
var forIn = _forIn;
var hasOwn = Object.prototype.hasOwnProperty;

exports = function forOwn(obj, fn, thisArg) {
  forIn(obj, function (val, key) {
    if (hasOwn.call(obj, key)) {
      return fn.call(thisArg, obj[key], key, obj);
    }
  });
};

export default exports;